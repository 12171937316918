import ActionTypes from '../../constants/ActionTypes';

// ============ creators ===========

// ============ actions ===========

const actionAddByTile = (tile, data) => {
	return {
		type: ActionTypes.AMAZONAS.TIME_SERIES.ADD,
		tile,
		data,
	};
};

// ============ export ===========

export default {
	addByTile: actionAddByTile,
};
