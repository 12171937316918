import classnames from 'classnames';
import PropTypes from 'prop-types';
import {IconTool, Tooltip} from '@gisatcz/ptr-atoms';
import ExpandableLabelsContainer from '../../common/ExpandableLabel/ExpandableLabelsContainer';
import RasterLayerLabel from '../RasterLayerLabel';
import VectorLayerLabel from '../VectorLayerLabel';

import './style.scss';

const MapWrapper = ({
	activeMapKey,
	labelsLeft,
	layersState,
	mapKey,
	mapSetMapKeys,
	noTools,
	removeMap,
	children,
}) => {
	const wrapperClasses = classnames('ptr-map-wrapper amazonas-MapWrapper', {
		active: mapKey === activeMapKey,
		'one-map': mapSetMapKeys?.length === 1,
	});

	const toolsClasses = classnames('amazonas-MapTools', {
		'is-left': labelsLeft,
	});

	return (
		<div className={wrapperClasses}>
			<div className={toolsClasses}>
				{layersState?.length ? (
					<ExpandableLabelsContainer className="amazonas-LayerLabelsContainer">
						{layersState.map(layerState => {
							if (layerState?.type) {
								return (
									<VectorLayerLabel
										key={layerState.key}
										layerState={layerState}
										mapKey={mapKey}
									/>
								);
							} else {
								return (
									<RasterLayerLabel
										key={layerState.key}
										layerState={layerState}
										mapKey={mapKey}
									/>
								);
							}
						})}
					</ExpandableLabelsContainer>
				) : null}
				{!noTools && mapSetMapKeys?.length > 1 ? (
					<IconTool
						className="amazonas-RemoveMapIcon"
						tooltip={{
							text: 'Remove map',
							position: 'left',
							component: Tooltip,
						}}
						onClick={() => removeMap(mapKey)}
						floating
						medium
						icon="ri-close"
					/>
				) : null}
			</div>
			{children}
		</div>
	);
};

MapWrapper.propTypes = {
	activeMapKey: PropTypes.string,
	children: PropTypes.object,
	labelsLeft: PropTypes.bool,
	layersState: PropTypes.array,
	mapKey: PropTypes.string,
	mapSetMapKeys: PropTypes.array,
	noTools: PropTypes.bool,
	removeMap: PropTypes.func,
};

export default MapWrapper;
