import {connect} from '@gisatcz/ptr-state';
import Action from '../../state/Action';
import Select from '../../state/Select';

import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		closed: Select.components.get(state, 'Intro', 'isClosed'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setClosed: closed => {
			dispatch(Action.components.set('Intro', 'isClosed', closed));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
