import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';

import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		layerTemplate: Select.layerTemplates.getByKey(
			state,
			ownProps.layerState.layerTemplateKey
		),
		period: Select.periods.getByKey(
			state,
			ownProps.layerState.metadataModifiers.periodKey
		),
		configForLayerTemplate: Select.amazonas.getConfigForLayerTemplate(
			state,
			ownProps.layerState.layerTemplateKey
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onRemove: () => {
			dispatch(
				Action.maps.removeMapLayer(ownProps.mapKey, ownProps.layerState.key)
			);
		},
		onOpacityChange: opacity => {
			dispatch(
				Action.maps.setMapLayerOpacity(
					ownProps.mapKey,
					ownProps.layerState.key,
					opacity / 100
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
