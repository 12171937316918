import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import scrollTo from '../../../utils/scrollTo';
import logo from '../../../assets/logo_noBackground.png';
import './style.scss';

const Hero = ({onExploreDataClick}) => {
	return (
		<div className="amazonas-Hero" id="hero">
			<div className="amazonas-Hero-text">
				<h1>Sentinel-1 for Science Amazonas</h1>
				<p>
					Forests help offset a quarter of anthropogenic emissions of
					fossil-fuel, and hold up to 70-90% of the Earth’s terrestrial carbon.
					Sentinel-1 for Science Amazonas is an exploratory scientific project,
					aimed at developing a simple and transparent approach to using
					Sentinel-1 satellite radar imagery to estimate forest area loss.
				</p>
				<div className="amazonas-Hero-buttons">
					<Button large primary onClick={onExploreDataClick}>
						Explore data
					</Button>
					<Button large ghost onClick={() => scrollTo('about')}>
						About project
					</Button>
				</div>
			</div>
			<div className="amazonas-Hero-logo">
				<img src={logo} alt="logo" />
			</div>
		</div>
	);
};

Hero.propTypes = {
	onExploreDataClick: PropTypes.func,
};

export default Hero;
