import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';
import ExpandableLabel, {
	ExpandableLabelBody,
	ExpandableLabelHeader,
} from '../../common/ExpandableLabel';
import OpacitySlider from '../../common/OpacitySlider';

import './style.scss';

const VectorLayerLabel = ({layerState, zIndex, onOpacityChange, onRemove}) => {
	const {opacity} = layerState;

	return (
		<>
			<ExpandableLabel
				floating
				className="amazonas-VectorLayerLabel"
				zIndex={zIndex}
			>
				<ExpandableLabelHeader>
					<VectorLayerLabelHeader
						color={layerState?.options?.style?.rules?.[0].styles?.[0]?.fill}
						title={layerState?.name}
					/>
				</ExpandableLabelHeader>
				<ExpandableLabelBody height={4}>
					<div className="amazonas-VectorLayerLabelBody">
						<div>
							<VectorLayerLabelItem title="Set opacity">
								<OpacitySlider
									value={opacity >= 0 ? opacity * 100 : 100}
									onChange={onOpacityChange}
								/>
							</VectorLayerLabelItem>
							<VectorLayerLabelItem onClick={onRemove} title="Remove layer">
								<Icon icon="close" />
							</VectorLayerLabelItem>
						</div>
					</div>
				</ExpandableLabelBody>
			</ExpandableLabel>
		</>
	);
};

VectorLayerLabel.propTypes = {
	layerState: PropTypes.object,
	layerTemplate: PropTypes.object,
	configForLayerTemplate: PropTypes.object,
	period: PropTypes.object,
	zIndex: PropTypes.number,
	onOpacityChange: PropTypes.func,
	onRemove: PropTypes.func,
};

const VectorLayerLabelHeader = ({color, title}) => {
	return (
		<div className="amazonas-VectorLayerLabelHeader">
			<div
				className="amazonas-VectorLayerLabelHeader-color"
				style={{background: color}}
			/>
			<div className="amazonas-VectorLayerLabelHeader-body">
				<div className="amazonas-VectorLayerLabelHeader-title">{title}</div>
			</div>
		</div>
	);
};

VectorLayerLabelHeader.propTypes = {
	color: PropTypes.string,
	period: PropTypes.string,
	title: PropTypes.string,
};

const VectorLayerLabelItem = ({
	title,
	url,
	isLink,
	dangerous,
	disabled,
	onClick,
	children,
}) => {
	const classes = classnames('amazonas-VectorLayerLabelBodyItem', {
		'is-hoverable': !!onClick || isLink,
		'is-dangerous': dangerous,
		'is-disabled': disabled,
	});

	if (isLink) {
		return (
			<a className={classes} href={url}>
				<div className="amazonas-VectorLayerLabelBodyItem-title">{title}</div>
				<div className="amazonas-VectorLayerLabelBodyItem-tool">{children}</div>
			</a>
		);
	} else {
		return (
			<div className={classes} onClick={onClick}>
				<div className="amazonas-VectorLayerLabelBodyItem-title">{title}</div>
				<div className="amazonas-VectorLayerLabelBodyItem-tool">{children}</div>
			</div>
		);
	}
};

VectorLayerLabelItem.propTypes = {
	title: PropTypes.string,
	dangerous: PropTypes.bool,
	disabled: PropTypes.bool,
	isLink: PropTypes.bool,
	onClick: PropTypes.func,
	url: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default VectorLayerLabel;
