import PropTypes from 'prop-types';
import moment from 'moment';

import './style.scss';

const ChartTooltip = ({point}) => {
	if (point) {
		const {xFormatted, y} = point.data;
		return (
			<div className="amazonas-ChartTooltip">
				<div className="amazonas-ChartTooltip-label">
					{xFormatted && moment(xFormatted).format('LL')}
				</div>
				<div className="amazonas-ChartTooltip-value">{y.toLocaleString()}</div>
			</div>
		);
	} else {
		return null;
	}
};

ChartTooltip.propTypes = {
	point: PropTypes.object,
};

export default ChartTooltip;
