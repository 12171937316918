import './style.scss';
import MapLegendAttributeScale from '../components/MapLegendAttributeScale';

const ConfidenceLegend = () => {
	return (
		<div className="amazonas-ConfidenceLegend">
			<MapLegendAttributeScale
				values={{
					fill: {
						inputInterval: [1, 100],
						outputInterval: ['rgb(0,34,81), rgb(128,126,121), rgb(255,234,70)'],
					},
				}}
			/>
		</div>
	);
};

export default ConfidenceLegend;
