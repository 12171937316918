import {commonActionTypes} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';

const specificActionTypes = utils.deepKeyMirror({
	AMAZONAS: {
		CONFIGURATION: {
			OPEN: {
				ADD: null,
				REMOVE: null,
			},
			UPDATE: null,
		},
		TIME_SERIES: {
			ADD: null,
		},
	},
});

export default {
	...commonActionTypes,
	...specificActionTypes,
};
