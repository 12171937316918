export default {
	key: 'tilesStyle',
	data: {
		nameDisplay: 'Data tiles',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						{
							fill: '#28ce94',
							fillOpacity: 0.3,
							outlineColor: '#333333',
							outlineWidth: 2,
							outlineOpacity: 1,
						},
						{
							attributeKey: 'TIME_SER',
							attributeValues: {
								N: {
									fill: '#cccccc',
								},
							},
						},
					],
				},
			],
		},
	},
};
