import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../state/Select';
import {mapSetKey} from '../../constants/app';

const mapStateToProps = state => {
	const activeMapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);
	return {
		layers: Select.amazonas.getTimelineLayers(state),
		activeMapKey,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
