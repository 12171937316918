import ActionTypes from '../../constants/ActionTypes';

const INITIAL_STATE = {
	byTileKey: {},
};

const addByTileKey = (state, tile, data) => {
	return {
		...state,
		byTileKey: {
			...state.byTileKey,
			[tile]: data,
		},
	};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.AMAZONAS.TIME_SERIES.ADD:
			return addByTileKey(state, action.tile, action.data);
		default:
			return state;
	}
};
