import PropTypes from 'prop-types';
import {
	ReactCompareSlider,
	ReactCompareSliderHandle,
} from 'react-compare-slider';
import Modal from 'react-modal';
import {connects} from '@gisatcz/ptr-state';
import {
	MapSet as PresentationMapSet,
	PresentationMap,
	ReactLeafletMap,
} from '@gisatcz/ptr-maps';
import ModalWindow from '../common/ModalWindow';
import {DeforestationProgressTitle} from '../DeforestationProgress/presentation';
import DeforestationProgress from '../DeforestationProgress';

import MapContainer from './MapContainer';
import MapWrapper from './MapWrapper';
import MapComponentsGroup from './MapComponentsGroup';
import MapAttribution from './MapAttribution';
import Scale from './Scale';
import AddMapControl from './AddMapControl';
import CompareMapsControl from './CompareMapsControl';
import LayersControl from './LayersControl';
import ZoomControls from './ZoomControls';

import {MAX_MAPS_IN_MAP_SET} from '../../constants/app';
import './style.scss';

const ConnectedMap = MapContainer(PresentationMap);
const ConnectedMapSet = connects.MapSet(PresentationMapSet);

const Map = MapContainer(PresentationMap);

Modal.setAppElement('#root');

const Maps = ({
	mapSetKey,
	maps,
	mode,
	viewLimits,
	isTimeSerieModalOpen,
	setTimeSerieModalClose,
}) => {
	return (
		<>
			<div className={'amazonas-Maps'}>
				{mode === 'compare' ? (
					<ReactCompareSlider
						onlyHandleDraggable
						handle={
							<ReactCompareSliderHandle
								buttonStyle={{position: 'absolute', top: 'calc(50% - 30px)'}}
							/>
						}
						className="amazonas-CompareSlider"
						itemOne={
							<Map
								wrapper={MapWrapper}
								wrapperProps={{labelsLeft: true, noTools: true}}
								mapComponent={ReactLeafletMap}
								stateMapKey={maps[0].key}
							/>
						}
						itemTwo={
							<Map
								wrapper={MapWrapper}
								wrapperProps={{noTools: true}}
								mapComponent={ReactLeafletMap}
								stateMapKey={maps[1].key}
							>
								<MapComponentsGroup className="amazonas-MapInfoElements">
									<MapComponentsGroup className="amazonas-AttributionScaleContainer">
										<MapAttribution mapSetKey={mapSetKey} />
										<Scale />
									</MapComponentsGroup>
								</MapComponentsGroup>
								<MapComponentsGroup className="amazonas-MapSetControls">
									<AddMapControl
										mapSetKey={mapSetKey}
										maxMapsCount={MAX_MAPS_IN_MAP_SET}
									/>
									<CompareMapsControl mapSetKey={mapSetKey} />
									<LayersControl mapSetKey={mapSetKey} />
									<ZoomControls viewLimits={viewLimits} />
								</MapComponentsGroup>
							</Map>
						}
					/>
				) : (
					<ConnectedMapSet
						stateMapSetKey={mapSetKey}
						mapComponent={ReactLeafletMap}
						connectedMapComponent={ConnectedMap}
						wrapper={MapWrapper}
					>
						<MapComponentsGroup className="amazonas-MapSetControls">
							<AddMapControl
								mapSetKey={mapSetKey}
								maxMapsCount={MAX_MAPS_IN_MAP_SET}
							/>
							<CompareMapsControl mapSetKey={mapSetKey} />
							<LayersControl mapSetKey={mapSetKey} />
							<ZoomControls viewLimits={viewLimits} />
						</MapComponentsGroup>
						<MapComponentsGroup className="amazonas-MapInfoElements">
							<MapComponentsGroup className="amazonas-AttributionScaleContainer">
								<MapAttribution mapSetKey={mapSetKey} />
								<Scale />
							</MapComponentsGroup>
						</MapComponentsGroup>
					</ConnectedMapSet>
				)}
			</div>
			<ModalWindow
				title={<DeforestationProgressTitle />}
				isOpen={isTimeSerieModalOpen || false}
				onClose={() => setTimeSerieModalClose()}
				className="amazonas-Modal ptr-dark"
			>
				<DeforestationProgress />
			</ModalWindow>
		</>
	);
};

Maps.propTypes = {
	maps: PropTypes.array,
	mapSetKey: PropTypes.string,
	mode: PropTypes.string,
	isTimeSerieModalOpen: PropTypes.bool,
	setTimeSerieModalClose: PropTypes.func,
	viewLimits: PropTypes.object,
};

export default Maps;
