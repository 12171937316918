import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Presentation from './presentation';

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		onExploreDataClick: closed => {
			dispatch(Action.components.set('Intro', 'isClosed', closed));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
