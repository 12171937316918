import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';

import Presentation from './presentation';
import {mapSetKey} from '../../../constants/app';

const mapStateToProps = (state, ownProps) => {
	return {
		activeMapKey: Select.maps.getMapSetActiveMapKey(state, mapSetKey),
		layersState: Select.maps.getLayersStateByMapKey(state, ownProps.mapKey),
		mapSetMapKeys: Select.maps.getMapSetMapKeys(state, mapSetKey),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		removeMap: mapKey => {
			dispatch(Action.maps.removeMap(mapKey));
		},
		// removeAllLayers: mapKey => {
		// 	dispatch(Action.maps.removeAllMapLayers(mapKey));
		// },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
