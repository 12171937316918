import scrollTo from '../../../utils/scrollTo';
import logo_esa from '../../../assets/logo_esa_white.png';

import './style.scss';

const IntroHeader = () => {
	return (
		<div className="amazonas-IntroHeader">
			<a href="https://www.esa.int/" target="_blank" rel="noreferrer noopener">
				<img
					className="amazonas-IntroHeader-logo"
					src={logo_esa}
					alt="logo esa"
				/>
			</a>
			<div className="amazonas-IntroHeader-divider">|</div>
			<div
				className="amazonas-IntroHeader-title"
				onClick={() => scrollTo('hero')}
			>
				Sentinel-1 for Science Amazonas
			</div>
		</div>
	);
};

export default IntroHeader;
