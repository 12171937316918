import Timeline from '../Timeline';
import Maps from '../Maps';
import PropTypes from 'prop-types';
import './style.scss';
import Intro from '../Intro';

const AppContent = ({activeViewKey}) => {
	return (
		<div className={'amazonas-App ptr-dark'}>
			{activeViewKey ? (
				<>
					<Intro />
					<Maps />
					<Timeline />
				</>
			) : null}
		</div>
	);
};

AppContent.propTypes = {
	activeViewKey: PropTypes.string,
};

export default AppContent;
