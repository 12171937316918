import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import {mapSetKey} from '../../../../constants/app';

import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const mapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);
	const mapLayerState = Select.maps.getLayerStateByLayerKeyAndMapKey(
		state,
		mapKey,
		ownProps.layerKey
	);

	return {
		visible: !!mapLayerState,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onClick: () => {
			dispatch(Action.amazonas.toggleLayerByLayerKey(ownProps.layerKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
