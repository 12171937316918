import classnames from 'classnames';
import PropTypes from 'prop-types';
import {IconTool} from '@gisatcz/ptr-atoms';
import IntroHeader from './IntroHeader';
import Hero from './Hero';

import './style.scss';
import About from './About';

const Intro = ({closed, setClosed}) => {
	const aboutClasses = classnames('amazonas-Intro', {
		'is-open': !closed,
	});

	return (
		<div className={aboutClasses}>
			<IconTool
				onClick={() => setClosed(!closed)}
				floating
				medium
				icon="ri-chevron-right"
				className="amazonas-Intro-control"
			/>
			<div className="amazonas-Intro-content">
				<IntroHeader />
				<div className="amazonas-Intro-body">
					<Hero />
					<About />
				</div>
			</div>
		</div>
	);
};

Intro.propTypes = {
	closed: PropTypes.bool,
	setClosed: PropTypes.func,
};

export default Intro;
