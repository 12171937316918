import {createSelector} from 'reselect';

const getAllAsObject = state => state.amazonas.timeSeries.byTileKey;

const getByTileKey = createSelector(
	[getAllAsObject, (state, tile) => tile],
	(models, key) => {
		return models?.[key] || null;
	}
);

export default {
	getByTileKey,
};
