import {forIn as _forIn} from 'lodash';
import Action from '../Action';
import Select from '../Select';
import {mapSetKey} from '../../constants/app';
import {tiles} from '../../data/vectorLayers';
import {confidence} from '../../data/wmsLayers';
import utils from '../../utils';

import timeSeriesActions from '../timeSeries/actions';
import configurationActions from './configuration/actions';

const toggleLayerByLayerKey = layerKey => {
	return (dispatch, getState) => {
		const state = getState();

		const mapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);
		const mapLayerState = Select.maps.getLayerStateByLayerKeyAndMapKey(
			state,
			mapKey,
			layerKey
		);

		const visibility = !mapLayerState;

		if (visibility) {
			let layer = null;
			if (layerKey === 'tiles') {
				layer = tiles;
			} else if (layerKey === 'confidence') {
				layer = confidence;
			}

			dispatch(Action.maps.addMapLayerToIndex(mapKey, layer));
		} else {
			dispatch(Action.maps.removeMapLayer(mapKey, layerKey));
		}
	};
};

const useTileStatisticsData = tile => {
	return dispatch => {
		// TODO if data present, do not make the request
		// TODO config
		const url = `https://gisat-gis.eu-central-1.linodeobjects.com/s4ama/jsons/timeseries_${tile}.json`;

		utils
			.request(url, 'GET', null, null)
			.then(data => {
				if (data) {
					const timeSerie = {};
					_forIn(data, (value, key) => {
						timeSerie[key] = value?.Count ? Number(value?.Count) : 0;
					});

					const finalData = {
						tile,
						data: timeSerie,
					};

					dispatch(timeSeriesActions.addByTile(tile, finalData));
				}
			})
			.catch(err => new Error(err));
	};
};

const handleSelectedTile = tile => {
	return dispatch => {
		const tileData = tiles.options.features.find(
			feature => feature.properties.Name === tile
		);

		dispatch(Action.components.set('TimeSerieModal', 'tile', tile));
		dispatch(Action.components.set('TimeSerieModal', 'data', true));
		dispatch(Action.components.set('TimeSerieModal', 'open', true));

		if (tileData?.properties?.TIME_SER === 'Y') {
			dispatch(useTileStatisticsData(tile));
		} else {
			dispatch(timeSeriesActions.addByTile(tile, {tile, noData: true}));
		}
	};
};

export default {
	toggleLayerByLayerKey,
	handleSelectedTile,

	configuration: configurationActions,
	timeSeries: timeSeriesActions,
};
