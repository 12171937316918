import geometries from './geometries/tiles.min.json';
import tilesStyle from './styles/tiles';

export const tiles = {
	key: 'tiles',
	name: 'Data tiles',
	type: 'vector',
	opacity: 0.8,
	options: {
		features: geometries.features,
		style: tilesStyle.data.definition,
		fidColumnName: 'Name',
		hoverable: true,
		hovered: {
			style: 'default',
		},
		selectable: true,
	},
};
