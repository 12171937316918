import {config as getConfig} from '@gisatcz/ptr-core';
import {Action as CommonAction} from '@gisatcz/ptr-state';
import Action from '../state/Action';
import Select from '../state/Select';
import amazonas from '../state/amazonas/actions';
import {appKey} from '../constants/app';
import {tiles} from '../data/vectorLayers';
import configurationActions from './amazonas/configuration/actions';

require('dotenv').config();

const getAppEnvConfig = () => {
	if (process?.env) {
		const apiBackendProtocol = process.env?.REACT_APP_apiBackendProtocol;
		const apiBackendHost = process.env?.REACT_APP_apiBackendHost;
		const apiBackendPath = process.env?.REACT_APP_apiBackendPath;
		const requestPageSize = process.env?.REACT_APP_requestPageSize;

		return {
			...(apiBackendProtocol ? {apiBackendProtocol} : {}),
			...(apiBackendHost ? {apiBackendHost} : {}),
			...(apiBackendPath ? {apiBackendPath} : {}),
			...(requestPageSize ? {requestPageSize} : {}),
		};
	} else {
		return {};
	}
};

function init(path) {
	return (dispatch, getState) => {
		dispatch(CommonAction.app.setBaseUrl(path));

		const config = getConfig(getAppEnvConfig());
		dispatch(CommonAction.app.updateLocalConfiguration(config));
		dispatch(CommonAction.app.setKey(appKey));

		dispatch(CommonAction.app.loadConfiguration()).then(() => {
			dispatch(
				CommonAction.layerTemplates.useIndexed(
					{
						application: true,
					},
					null,
					null,
					1,
					100,
					'init'
				)
			);

			dispatch(
				CommonAction.periods.useIndexed(
					{
						application: true,
					},
					null,
					null,
					1,
					100,
					'init'
				)
			);

			const viewKey = Select.app.getConfiguration(getState(), 'initialViewKey');
			if (viewKey) {
				dispatch(CommonAction.views.useKeys([viewKey])).then(() => {
					const view = Select.views.getByKey(getState(), viewKey);
					dispatch(
						CommonAction.views.applyAndSetActive(viewKey, CommonAction)
					).then(() => {
						// Add vector layer with tiles to the active map
						dispatch(Action.amazonas.toggleLayerByLayerKey(tiles.key));
						dispatch(
							configurationActions.updateStateFromView(
								view.data.state.amazonas.configuration
							)
						);
					});
				});
			}
		});
	};
}

export default {
	...CommonAction,
	init,
	amazonas,
};
