import {createSelector} from 'reselect';
import {forIn as _forIn} from 'lodash';
import Select from '../Select';
import {
	timelineLayerElementHeight,
	timelineLayerLineHeight,
} from '../../constants/app';

import configurationSelectors from './configuration/selectors';
import timeSeriesSelectors from '../timeSeries/selectors';

const getConfigForLayerTemplate = createSelector(
	[
		state => Select.app.getConfiguration(state, 'linkingByLayerTemplateKey'),
		(state, layerTemplateKey) => layerTemplateKey,
	],
	(config, layerTemplateKey) => {
		return (config && layerTemplateKey && config[layerTemplateKey]) || null;
	}
);

const getDataForChart = createSelector(
	[timeSeriesSelectors.getByTileKey],
	dataForTile => {
		if (dataForTile && !dataForTile.noData) {
			const data = [];
			_forIn(dataForTile.data, (value, key) => {
				if (value > 10) {
					const date = `${key.slice(0, 4)}-${key.slice(4, 6)}-${key.slice(6)}`;
					data.push({x: date, y: value});
				}
			});

			return [
				{
					id: dataForTile.tile,
					data,
				},
			];
		} else if (dataForTile && dataForTile.noData) {
			return dataForTile;
		} else {
			return null;
		}
	}
);

const getTimelineLayers = createSelector([], () => {
	let timelineLayers = [];

	const years = {
		lineHeight: timelineLayerLineHeight,
		elementHeight: timelineLayerElementHeight,
		legend: {
			title: 'Forest loss in 5 years',
		},
		controlMapState: 'toggle',
		items: [
			{
				periods: {
					filter: {
						periodKey: 'afc49d64-0b56-46c1-b9ca-f58b6eb922f9',
						layerTemplateKey: '1d81c1c4-f0b3-4cba-9ffb-b8341dc0cdc8',
					},
					filterByActive: {
						application: true,
					},
				},
				colors: {
					basic: 'var(--accent25)',
					active: 'var(--accent50)',
				},
				states: ['basic', 'active', 'hover', 'disabled'],
				activeStates: ['basic'],
				mapZIndex: 1,
				layerState: {
					layerTemplateKey: '1d81c1c4-f0b3-4cba-9ffb-b8341dc0cdc8',
					metadataModifiers: {
						applicationKey: 'esaS14scienceAmazonas22',
					},
				},
			},
		],
		allowNonActiveLayer: true,
	};

	const months = {
		lineHeight: timelineLayerLineHeight,
		elementHeight: timelineLayerElementHeight,
		legend: {
			title: 'Forest loss in 3 months',
		},
		controlMapState: 'toggle',
		items: [
			{
				periods: {
					filter: {
						layerTemplateKey: '475a0977-1c1b-4143-ae43-c117e11f2a5e',
					},
					filterByActive: {
						application: true,
					},
				},
				colors: {
					basic: 'var(--accent25)',
					active: 'var(--accent50)',
				},
				states: ['basic', 'active', 'hover', 'disabled'],
				activeStates: ['basic'],
				mapZIndex: 0,
				layerState: {
					layerTemplateKey: '475a0977-1c1b-4143-ae43-c117e11f2a5e',
					metadataModifiers: {
						applicationKey: 'esaS14scienceAmazonas22',
					},
				},
			},
		],
		allowNonActiveLayer: true,
	};

	timelineLayers.push(years);
	timelineLayers.push(months);
	return timelineLayers;
});

export default {
	getConfigForLayerTemplate,
	getDataForChart,
	getTimelineLayers,

	configuration: configurationSelectors,
	timeSeries: timeSeriesSelectors,
};
