import {ResponsiveLine} from '@nivo/line';
import PropTypes from 'prop-types';
import {max as _max} from 'lodash';
import ChartTooltip from './ChartTooltip';

const theme = {
	axis: {
		domain: {
			line: {
				stroke: 'var(--base60)',
			},
		},
		ticks: {
			line: {
				stroke: 'var(--base20)',
			},
			text: {
				fill: 'var(--base60)',
			},
		},
		legend: {
			text: {
				fontSize: 12,
				fill: 'var(--base60)',
			},
		},
	},
	grid: {
		line: {
			stroke: 'var(--base20)',
		},
	},
	crosshair: {
		line: {
			stroke: 'var(--base60)',
		},
	},
};

const defaultMax = 10000;

const LineChart = ({data}) => {
	let max;
	const timeSerie = data?.[0]?.data;
	if (timeSerie) {
		max = _max(timeSerie.map(item => item.y));
	}

	return (
		<ResponsiveLine
			data={data}
			theme={theme}
			colors={['var(--accent50)']}
			margin={{top: 10, right: 80, bottom: 50, left: 80}}
			xScale={{
				type: 'time',
				format: '%Y-%m-%d',
				min: '2017-01-01',
				max: '2022-01-01',
			}}
			xFormat="time:%Y-%m-%d"
			yScale={{
				type: 'linear',
				min: 0,
				max: max > defaultMax ? max : defaultMax,
				stacked: false,
				reverse: false,
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				format: '%B %Y',
				tickValues: 'every 6 month',
			}}
			axisLeft={{
				format: value => value.toLocaleString(),
				tickValues: 5,
				legend: 'Area [ha]',
				legendOffset: -70,
				legendPosition: 'middle',
			}}
			gridYValues={5}
			pointSize={8}
			pointColor={{from: 'serieColor'}}
			pointBorderWidth={2}
			pointBorderColor={{from: 'serieColor'}}
			pointLabelYOffset={-12}
			useMesh={true}
			tooltip={ChartTooltip}
		/>
	);
};

LineChart.propTypes = {
	data: PropTypes.array,
};

export default LineChart;
