import {connect} from '@gisatcz/ptr-state';
import Select from '../../state/Select';
import Presentation from './presentation';
import Action from '../../state/Action';

const mapStateToProps = state => {
	const mapSetKey = Select.maps.getActiveSetKey(state);

	return {
		mapSetKey,
		viewLimits: Select.maps.getMapSetViewLimits(state, mapSetKey),
		maps: Select.maps.getMapSetMaps(state, mapSetKey),
		mode: Select.components.get(state, 'Maps', 'mode'),
		isTimeSerieModalOpen: Select.components.get(
			state,
			'TimeSerieModal',
			'open'
		),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setTimeSerieModalClose: () => {
			dispatch(Action.components.set('TimeSerieModal', 'open', false));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
