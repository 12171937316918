import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';
import ExpandableLabel, {
	ExpandableLabelBody,
	ExpandableLabelHeader,
} from '../../common/ExpandableLabel';
import OpacitySlider from '../../common/OpacitySlider';

import './style.scss';

const LayerLabel = ({
	layerState,
	layerTemplate,
	configForLayerTemplate,
	period,
	zIndex,
	onOpacityChange,
	onRemove,
}) => {
	const {opacity} = layerState;
	const {color} = configForLayerTemplate;

	return (
		<>
			<ExpandableLabel floating className="amazonas-LayerLabel" zIndex={zIndex}>
				<ExpandableLabelHeader>
					<LayerLabelHeader
						color={color}
						title={layerTemplate?.data?.nameDisplay}
						period={period?.data?.period}
					/>
				</ExpandableLabelHeader>
				<ExpandableLabelBody height={4}>
					<div className="amazonas-LayerLabelBody">
						<div>
							<LayerLabelBodyItem title="Set opacity">
								<OpacitySlider
									value={opacity >= 0 ? opacity * 100 : 100}
									onChange={onOpacityChange}
								/>
							</LayerLabelBodyItem>
							<LayerLabelBodyItem onClick={onRemove} title="Remove layer">
								<Icon icon="close" />
							</LayerLabelBodyItem>
							{/*<LayerLabelBodyItem*/}
							{/*	url={downloadUrl}*/}
							{/*	isLink*/}
							{/*	title="Download layer source data"*/}
							{/*>*/}
							{/*	<Icon icon="download" />*/}
							{/*</LayerLabelBodyItem>*/}
						</div>
					</div>
				</ExpandableLabelBody>
			</ExpandableLabel>
		</>
	);
};

LayerLabel.propTypes = {
	layerState: PropTypes.object,
	layerTemplate: PropTypes.object,
	configForLayerTemplate: PropTypes.object,
	period: PropTypes.object,
	zIndex: PropTypes.number,
	onOpacityChange: PropTypes.func,
	onRemove: PropTypes.func,
};

const LayerLabelHeader = ({title, period, color}) => {
	let [start, end] = (period && period.split('/')) || [];
	if (start && end && start.length > 4) {
		start = moment(start).format('MMMM YYYY');
		end = moment(end).format('MMMM YYYY');
	}

	return (
		<div className="amazonas-LayerLabelHeader">
			<div
				className="amazonas-LayerLabelHeader-color"
				style={{background: color}}
			/>
			<div className="amazonas-LayerLabelHeader-body">
				<div className="amazonas-LayerLabelHeader-title">{title}</div>
				<div className="amazonas-LayerLabelHeader-period">{`${start} - ${end}`}</div>
			</div>
		</div>
	);
};

LayerLabelHeader.propTypes = {
	color: PropTypes.string,
	period: PropTypes.string,
	title: PropTypes.string,
};

const LayerLabelBodyItem = ({
	title,
	url,
	isLink,
	dangerous,
	disabled,
	onClick,
	children,
}) => {
	const classes = classnames('amazonas-LayerLabelBodyItem', {
		'is-hoverable': !!onClick || isLink,
		'is-dangerous': dangerous,
		'is-disabled': disabled,
	});

	if (isLink) {
		return (
			<a className={classes} href={url}>
				<div className="amazonas-LayerLabelBodyItem-title">{title}</div>
				<div className="amazonas-LayerLabelBodyItem-tool">{children}</div>
			</a>
		);
	} else {
		return (
			<div className={classes} onClick={onClick}>
				<div className="amazonas-LayerLabelBodyItem-title">{title}</div>
				<div className="amazonas-LayerLabelBodyItem-tool">{children}</div>
			</div>
		);
	}
};

LayerLabelBodyItem.propTypes = {
	title: PropTypes.string,
	dangerous: PropTypes.bool,
	disabled: PropTypes.bool,
	isLink: PropTypes.bool,
	onClick: PropTypes.func,
	url: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default LayerLabel;
