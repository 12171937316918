import {connect} from '@gisatcz/ptr-state';
import Select from '../../state/Select';
import Presentation from './presentation';

const mapStateToProps = state => {
	const tile = Select.components.get(state, 'TimeSerieModal', 'tile');

	return {
		tile,
		data: Select.amazonas.getDataForChart(state, tile),
	};
};

export default connect(mapStateToProps)(Presentation);
