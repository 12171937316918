import PropTypes from 'prop-types';
import LineChart from './LineChart';

import './style.scss';

export const DeforestationProgressTitle = () => {
	return (
		<h3 className="amazonas-DeforestationProgressTitle">Area of forest loss</h3>
	);
};

const DeforestationProgress = ({tile, data}) => {
	let content = null;
	if (data?.noData) {
		content = <p>No data present for tile {tile}.</p>;
	} else if (data) {
		content = (
			<>
				<h4>Data tile {tile}</h4>
				<div className="amazonas-DeforestationProgress-chart">
					<LineChart data={data} />
				</div>
			</>
		);
	}

	return <div className="amazonas-DeforestationProgress">{content}</div>;
};

DeforestationProgress.propTypes = {
	data: PropTypes.array,
	tile: PropTypes.string,
};

export default DeforestationProgress;
