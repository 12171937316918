export const confidence = {
	key: 'confidence',
	name: 'Confidence',
	type: 'wms',
	options: {
		url: 'https://ptr.gisat.cz/mapproxy/sen4ama_prod/wms',
		params: {
			layers: 'layer_sen4ama_prod_confidence_layers_mosaic_Sept_2022',
		},
	},
};
