import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';

import Presentation from './presentation';

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onRemove: () => {
			dispatch(
				Action.maps.removeMapLayer(ownProps.mapKey, ownProps.layerState.key)
			);
		},
		onOpacityChange: opacity => {
			dispatch(
				Action.maps.setMapLayerOpacity(
					ownProps.mapKey,
					ownProps.layerState.key,
					opacity / 100
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
