import PropTypes from 'prop-types';
import {createElement} from 'react';
import './style.scss';

const LayerControl = ({onClick, title, visible, Legend}) => {
	return (
		<div className="amazonas-LayerControl">
			<label className={'ptr-Checkbox'}>
				<input type="checkbox" checked={visible} onChange={onClick} />
				<span>{title}</span>
			</label>
			{visible && Legend ? createElement(Legend) : null}
		</div>
	);
};

LayerControl.propTypes = {
	onClick: PropTypes.func,
	title: PropTypes.string,
	visible: PropTypes.bool,
	Legend: PropTypes.func,
};

export default LayerControl;
