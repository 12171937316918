import PropTypes from 'prop-types';
import './style.scss';

import schema from '../../../assets/schema.jpg';
import gisat from '../../../assets/img/gisat.png';
import agresta from '../../../assets/img/agresta.png';
import nls from '../../../assets/img/nls.png';
import nmbu from '../../../assets/img/nmbu.png';
import {Icon} from '@gisatcz/ptr-atoms';

const Section = ({children}) => {
	return <div className="amazonas-About-section">{children}</div>;
};

Section.propTypes = {
	children: PropTypes.node,
};

const About = () => {
	return (
		<div className="amazonas-About" id="about">
			<Section>
				<h2>Summary</h2>
				<p>
					Timely forest monitoring, with easily comprehensible data analysis and
					outputs, is growing increasingly urgent. Since 2015, the world&apos;s
					tropical forests can be observed regularly at an unprecedented
					6/12-day interval with the satellites of the Sentinel-1 mission.
					Millions of gigabytes of C-band synthetic aperture radar (SAR) scenes
					are acquired day and night, regardless of cloud cover, haze, smoke or
					aerosols, potentially allowing deforestation and forest degradation to
					be monitored at least biweekly. The challenge, however, lies in
					finding adequate methods to extract meaningful indicators of forest
					loss from the vast amount of incoming SAR data, such that anomalies in
					the time-series can be regularly and consistently detected across
					tropical forests. Such forest-monitoring methods should be transparent
					and easily understandable to the wider public, hence enabling
					confidence in their use across various public and private sectors.{' '}
				</p>
				<img className="amazonas-About-schema" src={schema} alt="schema"></img>
				<p>
					The Sentinel-1 for Science Amazonas project presents a simple
					space-time data cube design (referred to as StatCubes), where
					statistical information relevant to identify deforestation is
					extracted at each point in the SAR backscatter time-series. The cubes
					do not rely on decades of historical data, and the effects of
					seasonality, including rainfall, are inherently masked out. With this
					approach, the project demonstrates the use of Sentinel-1 data to
					create a dynamic deforestation product over the Amazon basin. Billions
					of pixels from the Sentinel-1 satellites from 2015 to December 2021,
					each representing a 20 x 20 m of forest, are harmonised under the
					<i>StatCubes</i> design, and a simple thresholding approach to detect
					forest loss is demonstrated in the first version of the results. The
					largest challenge in the project was the vast amount of data handling
					and processing; a number of user-friendly software tools were
					developed to access the data efficiently, processing over 450 TB of
					data to create the forest loss maps. A specialised production system
					was set up to manage and monitor the processing of data.
				</p>
				<p>
					As a follow on of the project, the next scientific goal is to achieve
					a product of carbon loss from land cover change (i.e. forest loss),
					working together with the ESA <i>Climate Change Initiative</i> (CCI)
					science team, a goal that will contribute to the ESA{' '}
					<i>Carbon Science Cluster.</i>
				</p>
			</Section>
			<Section>
				<h2>Coordinator</h2>
				<div>Dr. Neha Joshi</div>
				<div>GISAT s.r.o.</div>
				<div>Remote Sensing Expert</div>
				<div>
					E-mail: <a href="mailto:neha.joshi@gisat.cz">neha.joshi@gisat.cz</a>
				</div>
			</Section>
			<Section>
				<h2>Team</h2>
				<p>
					Sentinel-1 for Science Amazonas is implemented by a consortium of four
					partners - Gisat (Prime, Czechia, Copernicus service provider),
					Agresta (Spain, worldwide forest consultancy), Norwegian University of
					Life Sciences (Norway, leading national academic institution) and the
					Finnish Geospatial Research Institute (Finland, international
					innovative scientific research institute). The team uniquely combines
					complementary and strong backgrounds in forestry and carbon
					assessments, multi-temporal SAR analysis and data fusion, and
					large-data processing capabilities.
				</p>
				<div className="amazonas-partners">
					<a
						href="https://gisat.cz/"
						target="_blank"
						rel="noreferrer noopener"
						className="amazonas-partner-card"
					>
						<div>
							<div className="amazonas-partner-card-img-wrapper">
								<img alt="gisat_logo" src={gisat} />
							</div>
							<div className="amazonas-partner-card-title">GISAT s.r.o.</div>
							<div className="amazonas-partner-card-subtitle">Prague (CZE)</div>
							<div className="amazonas-partner-card-person">Dr. Neha Joshi</div>
							<div className="amazonas-partner-card-person">
								Mr. Lubos Kucera
							</div>
							<div className="amazonas-partner-card-person">
								Mr. Sivasankar Arul
							</div>
						</div>
					</a>
					<a
						href="https://agresta.org/en/"
						target="_blank"
						rel="noreferrer noopener"
						className="amazonas-partner-card"
					>
						<div>
							<div className="amazonas-partner-card-img-wrapper">
								<img alt="agresta_logo" className="smaller" src={agresta} />
							</div>
							<div className="amazonas-partner-card-title">AGRESTA S.Coop</div>
							<div className="amazonas-partner-card-subtitle">Madrid (ESP)</div>
							<div className="amazonas-partner-card-person">
								Dr. Jessica Esteban
							</div>
							<div className="amazonas-partner-card-person">Dr. Nur Algeet</div>
							<div className="amazonas-partner-card-person">
								Dr. Mariluz Guillén
							</div>
							<div className="amazonas-partner-card-person">
								Dr. Alfredo Fernández
							</div>
						</div>
					</a>
					<a
						href="https://www.nmbu.no/en"
						target="_blank"
						rel="noreferrer noopener"
						className="amazonas-partner-card"
					>
						<div>
							<div className="amazonas-partner-card-img-wrapper">
								<img alt="nmbu_logo" src={nmbu} />
							</div>
							<div className="amazonas-partner-card-title">
								Norwegian University of Life Sciences
							</div>
							<div className="amazonas-partner-card-subtitle">Ås (NOR)</div>
							<div className="amazonas-partner-card-person">
								Professor Erik Næsset
							</div>
							<div className="amazonas-partner-card-person">
								Professor Terje Gobakken
							</div>
						</div>
					</a>
					<a
						href="https://www.maanmittauslaitos.fi/en/research"
						target="_blank"
						rel="noreferrer noopener"
						className="amazonas-partner-card"
					>
						<div>
							<div className="amazonas-partner-card-img-wrapper">
								<img alt="nls_logo" src={nls} />
							</div>
							<div className="amazonas-partner-card-title">
								Finnish Geospatial Research Institute
							</div>
							<div className="amazonas-partner-card-subtitle">
								Kirkkonummi (FIN)
							</div>
							<div className="amazonas-partner-card-person">
								Dr. Mika Karjalainen
							</div>
							<div className="amazonas-partner-card-person">
								Ms. Samantha Wittke
							</div>
							<div className="amazonas-partner-card-person">
								Ms. Kirsi Karila
							</div>
							<div className="amazonas-partner-card-person">
								Dr. Eetu Puttonen
							</div>
						</div>
					</a>
				</div>
			</Section>
			<Section>
				<h2>Open resources</h2>
				<ul className="amazonas-About-list">
					<li>
						<a
							href="https://drive.google.com/file/d/14nNZqO44M3-dINF4ugqsQ0mvU0gBuooq/view?usp=drive_link"
							download
						>
							<Icon icon="ri-download" />
							Complete multi-temporal change detection product covering entire
							Amazonas
						</a>
						<span>(1,3 GB, right click -&#62; Save link as...)</span>
					</li>
					{/*<li>*/}
					{/*	<a*/}
					{/*		href="http://download.gisat.cz/download/s14ama/SEN4AMA_CHANGE_MASK_MOSAIC_150123.zip"*/}
					{/*		rel="noreferrer noopener"*/}
					{/*		download*/}
					{/*	>*/}
					{/*		<Icon icon="ri-download" />*/}
					{/*		Forest loss in 5 years - Change mask mosaic*/}
					{/*	</a>*/}
					{/*	<span>(88,8 MB, right click -&#62; Save link as...)</span>*/}
					{/*</li>*/}
					{/*<li>*/}
					{/*	<a*/}
					{/*		href=" http://download.gisat.cz/download/s14ama/SEN4AMA_CHANGE_CONF_MOSAIC_150123.zip"*/}
					{/*		rel="noreferrer noopener"*/}
					{/*		download*/}
					{/*	>*/}
					{/*		<Icon icon="ri-download" />*/}
					{/*		Forest loss in 5 years - Change confidence mosaic*/}
					{/*	</a>*/}
					{/*	<span>(117 MB, right click -&#62; Save link as...)</span>*/}
					{/*</li>*/}
					<li>
						<a
							target="_blank"
							rel="noreferrer noopener"
							href="https://drive.google.com/file/d/1GhHjCZkH1tDdSY1-x3-hGKUkGv2XehFD/view?usp=sharing"
						>
							<Icon icon="file-export" />
							SEN4AMA Product Metadata Description
						</a>
					</li>
					<li>
						<a
							target="_blank"
							rel="noreferrer noopener"
							href="https://drive.google.com/file/d/1WEEpEbnQBfYomVdiyu4mR31psVM07bMR/view?usp=drive_link"
						>
							<Icon icon="file-export" />
							SEN4AMA Algorithm Theoretical Basis Documents (ATBD)
						</a>
					</li>
					<li>
						<a
							target="_blank"
							rel="noreferrer noopener"
							href="https://github.com/gisat/s14amazonas"
						>
							<Icon icon="external-link" />
							SEN4AMA Gitlab repository
						</a>
					</li>
				</ul>
			</Section>
			<Section>
				<div className="amazonas-About-footer">
					<p>
						Sentinel-1 for Science Amazonas is funded by the European Space
						Agency.
					</p>
					<h4>ESA - European Space Agency</h4>
					<div>
						<div>Dr. Anca Anghelea</div>
						<div>Earth Observation Open Data Scientist</div>
						<div>Science, Applications and Climate Department</div>
						<div>Directorate of Earth Observation Programmes</div>
						<div>
							anca.anghelea@esa.int | T +39 06 94180 275 |{' '}
							<a
								href="https://esa.int"
								target="_blank"
								rel="noreferrer noopener"
							>
								www.esa.int
							</a>
						</div>
					</div>
					<br />
					<p>
						Relevant Links:{' '}
						<a
							href="https://eo4society.esa.int/communities/scientists/esa-carbon-science-cluster/"
							target="_blank"
							rel="noreferrer noopener"
						>
							ESA Carbon Science Cluster
						</a>{' '}
						|{' '}
						<a
							href="https://eo4society.esa.int/"
							target="_blank"
							rel="noreferrer noopener"
						>
							EO science for society
						</a>
					</p>
				</div>
			</Section>
		</div>
	);
};

export default About;
